/// <reference path="o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts" />

/**********************************************************************/
/* ----        IMPORTANT! Read before making any changes         ---- */
/**********************************************************************/
/* ----           This file is part of a set of files            ---- */
/* ----            Any changes here MUST be added to:            ---- */
/**********************************************************************/
/* - o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts   */
/* - o365.pwa.modules.client.dexie.databases.O365PWAGlobal.ts         */
/* - o365.pwa.modules.sw.dexie.databases.O365PWAGlobal.ts             */
/**********************************************************************/

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { IFileStoreRecordOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';
import type { FileStoreRecord as TFileStoreRecord } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';
import type { Table } from 'o365.pwa.declaration.sw.dexie.d.ts';

import type * as O365PWAGlobalModule from 'o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

/* ------------------------------------------------------------ //
    This file is mirrored

    ANY CHANGES MUST BE REPLICATED IN

    o365.pwa.modules.client.dexie.databases.O365PWAGlobal.ts
// ------------------------------------------------------------ */
(() => {
    const { FileStoreRecord } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts')>("o365.pwa.modules.sw.dexie.objectStores.FileStoreFile.ts");

    class O365PWAGlobal extends self.Dexie.latestVersion.Dexie implements O365PWAGlobalModule.O365PWAGlobal {
        public fileStore!: Table<TFileStoreRecord, string>;

        constructor() {

            super('O365_PWA_GLOBAL');

            super.version(1).stores({
                fileStore: FileStoreRecord.fileStoreRecordDexieSchema
            });

            this.fileStore.mapToClass(FileStoreRecord);
        }

        public async retrieveFileStoreRecord(primKey: string): Promise<TFileStoreRecord | null> {
            return await this.fileStore.get(primKey) ?? null;
        }

        public async retrieveFileStoreRecords(primKeys: Array<string>): Promise<Array<TFileStoreRecord | null>> {
            const fileStoreRecords: Array<TFileStoreRecord | undefined | null> = await this.fileStore.bulkGet(primKeys) as Array<TFileStoreRecord | undefined>;

            for (let i = 0; i < fileStoreRecords.length; i++) {
                if (fileStoreRecords[i] === undefined) {
                    fileStoreRecords[i] = null;
                }
            }

            return fileStoreRecords as Array<TFileStoreRecord | null>;
        }

        public async createFileStoreRecord(fileStoreRecordOptions: IFileStoreRecordOptions): Promise<void> {
            let fileStoreRecord: TFileStoreRecord;

            if (fileStoreRecordOptions.data instanceof Blob) {
                fileStoreRecord = await FileStoreRecord.fromBlob(fileStoreRecordOptions as IFileStoreRecordOptions<Blob>)
            } else if (fileStoreRecordOptions.data instanceof ArrayBuffer) {
                fileStoreRecord = new FileStoreRecord(fileStoreRecordOptions as IFileStoreRecordOptions<ArrayBuffer>)
            } else {
                throw new Error('data must be of type Blob or ArrayBuffer');
            }

            await fileStoreRecord.save();
        }

        public async bulkCreateFileStoreRecords(fileStoreRecordsOptions: Array<IFileStoreRecordOptions>): Promise<void> {
            const fileStoreRecords = fileStoreRecordsOptions.map(async (fileStoreRecordOptions) => {
                let fileStoreRecord: TFileStoreRecord;

                if (fileStoreRecordOptions.data instanceof Blob) {
                    fileStoreRecord = await FileStoreRecord.fromBlob(fileStoreRecordOptions as IFileStoreRecordOptions<Blob>)
                } else if (fileStoreRecordOptions.data instanceof ArrayBuffer) {
                    fileStoreRecord = new FileStoreRecord(fileStoreRecordOptions as IFileStoreRecordOptions<ArrayBuffer>)
                } else {
                    throw new Error('data must be of type Blob or ArrayBuffer');
                }

                return fileStoreRecord
            });

            await this.bulkUpdateFileStoreRecords(await Promise.all(fileStoreRecords));
        }

        public async updateFileStoreRecord(fileStoreRecord: TFileStoreRecord): Promise<void> {
            await this.fileStore.put(fileStoreRecord);
        }

        public async bulkUpdateFileStoreRecords(fileStoreRecords: Array<TFileStoreRecord>): Promise<void> {
            await this.fileStore.bulkPut(fileStoreRecords);
        }

        public async destroyFileStoreRecord(primKey: string): Promise<void> {
            await this.fileStore.delete(primKey);
        }

        public async bulkDestroyFileStoreRecords(primKeys: Array<string>): Promise<void> {
            await this.fileStore.bulkDelete(primKeys);
        }

        public async bulkDestroyFileStoreRecordsByAppId(appID: string): Promise<void> {
            const fileStore = this.fileStore.where("appID").equals(appID);
            await fileStore.delete();
        }
    }

    let _instance: O365PWAGlobal | null = null;

    self.o365.exportScripts<typeof import('o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts')>({
        O365PWAGlobal,
        o365PWAGlobal: {
            get instance() {
                _instance ??= new O365PWAGlobal();

                return _instance;
            }
        },
        self
    });
})();
